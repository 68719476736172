<template>
  <div>
    <v-parallax
      :height="height"
      :src="image"
      class="text-center"
      :class="fixHeight ? 'fix' : ''"
    >
      <v-overlay absolute :opacity="opacity" color="black">
        <h2
          v-if="titleHTML"
          class="display-3 font-weight-bold d-flex"
          :class="
            $vuetify.breakpoint.mdAndUp
              ? ''
              : 'd-flex flex-column text-center align-center mx-auto'
          "
          v-html="title"
        />
        <h2 v-else class="display-2 mx-auto">{{ title }}</h2>
      </v-overlay>
    </v-parallax>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    imageIndex: {
      type: [Number, String],
      default: null,
    },
    imageType: {
      type: String,
      default: "",
    },
    height: {
      type: [String, Number],
      default: "",
    },
    button: {
      type: Object,
      default: () => {},
    },
    opacity: {
      type: String,
      default: ".4",
    },
    parallax: {
      type: Boolean,
      default: true,
    },
    titleHTML: {
      type: Boolean,
      default: false,
    },
    fixHeight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 980px) {
  h2 {
    max-width: 50%;
  }
}

::v-deep .fix img {
  @media only screen and (min-width: 600px) {
    transform: translate(-50%, 11vw) !important;
  }
  @media only screen and (min-width: 960px) {
    transform: translate(-50%, 0vw) !important;
  }
  @media only screen and (min-width: 1200px) {
    transform: translate(-50%, 7vw) !important;
  }
  @media only screen and (min-width: 1300px) {
    transform: translate(-50%, 11vw) !important;
  }
  @media only screen and (min-width: 1500px) {
    transform: translate(-50%, 15vw) !important;
  }
}
</style>
