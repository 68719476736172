<template>
  <div class="white">
    <v-row>
      <v-col class="text-center">
        <h3 class="display-2 mt-4">Our Clients</h3>
      </v-col>
    </v-row>
    <v-row
      class="d-flex flex-wrap justify-center"
      :class="$vuetify.breakpoint.mdAndUp ? 'py-4' : 'py-10'"
    >
      <v-col>
        <v-slide-group
          v-model="model"
          show-arrows="mobile"
          :center-active="!$vuetify.breakpoint.smAndDown"
        >
          <v-slide-item v-for="slide in slides" :key="slide.i">
            <v-img
              :src="slide.image"
              height="150"
              contain
              width="300"
              class="mx-6"
            />
          </v-slide-item>
        </v-slide-group>
        <div class="text-center mt-4" v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon
            v-for="n in icons"
            :key="n"
            @click="model = n"
            :color="model == n ? 'primary' : ''"
          >
            mdi-circle-medium
          </v-icon>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: 0,
      iconsTablet: [0, 3, 6, 9, 13],
      iconsDesktop: [0, 6, 9, 12, 15, 18],
      iconsDesktopWoodwork: [0, 6, 9, 12, 15, 18, 21, 24],
    };
  },
  computed: {
    icons() {
      let result;
      console.log(this.$route.name);
      if (this.$vuetify.breakpoint.mdAndUp)
        result =
          this.$route.name == "Woodshop"
            ? this.iconsDesktopWoodwork
            : this.iconsDesktop;
      else if (this.$vuetify.breakpoint.sm) result = this.iconsTablet;
      return result;
    },
  },
  methods: {
    iterate() {
      let index = 0;
      for (let i = 0; i < this.icons.length; i++) {
        setTimeout(() => {
          if (i == this.icons.length - 1) {
            this.model = i;
            this.iterate();
          }
          this.model = i = this.icons[i];
        }, 3000 + index);
        index += 4000;
      }
    },
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.model == 0) {
      this.iterate();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  display: none !important;
}
</style>
