<template>
  <v-row class="banner" :class="noSpace ? '' : 'my-12 py-4'">
    <v-col class="py-12">
      <v-row>
        <v-col>
          <h2 v-if="headHTML" class="text-center display-2" v-html="heading" />
          <h2 v-else class="text-center display-2">{{ heading }}</h2>
        </v-col>
      </v-row>
      <v-row
        :class="
          reverse && !$vuetify.breakpoint.smAndDown ? 'flex-row-reverse' : ''
        "
      >
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-img
            rel="preload"
            v-if="imageIndex != null"
            :src="image"
            max-width="80%"
            :height="imageHeight"
          />
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center flex-column">
          <div
            class="d-flex flex-column justify-center"
            :class="containerClass"
          >
            <h2 class="white--text">{{ subheading }}</h2>
            <p
              class="description"
              v-for="d in description"
              :key="d.i"
              v-html="d"
            />
            <h3 v-if="list.length > 0" class="mb-0">Services</h3>
            <v-list dense color="transparent">
              <v-list-item
                class="white--text"
                v-for="item in list"
                :key="item.i"
              >
                <v-list-item-action class="mx-0 my-0">
                  <v-icon color="primary" class="pr-2">mdi-plus</v-icon>
                </v-list-item-action>
                <a :href="item.to">{{ item.title }}</a>
              </v-list-item>
            </v-list>
          </div>
          <div class="d-flex">
            <v-btn
              width="150"
              rounded
              color="primary"
              v-if="btn != null"
              :to="btn.to"
              outlined
              :class="$vuetify.breakpoint.smAndDown ? 'mx-auto mt-6' : btnClass"
            >
              {{ btn.text }}
            </v-btn>
            <v-btn
              width="150"
              rounded
              color="primary"
              v-if="btn2 != null"
              :to="btn2.to"
              outlined
              :class="$vuetify.breakpoint.smAndDown ? 'mx-auto mt-6' : btnClass"
            >
              {{ btn2.text }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    btnClass() {
      let result;
      if (this.reverse) result = "ml-16";
      return result;
    },
    containerClass() {
      let result;
      if (this.$vuetify.breakpoint.smAndDown) result = "ml-10";
      else if (this.reverse) result = "ml-16";

      return result;
    },
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageIndex: {
      type: String,
      default: null,
    },
    imageType: {
      type: String,
      default: null,
    },
    imageHeight: {
      type: String,
      default: "400",
    },
    heading: {
      type: String,
      default: "",
    },
    subheading: {
      type: String,
      default: "",
    },
    description: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    btn: {
      type: Object,
      default: () => {},
    },
    btn2: {
      type: Object,
      default: () => {},
    },
    noSpace: {
      type: Boolean,
      default: false,
    },
    headHTML: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  color: white;
}
.description {
  max-width: 600px;
}

@media only screen and (max-width: 959px) {
  .description {
    max-width: 90%;
  }
}
</style>
