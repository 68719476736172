<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="overlay_text-container">
      <h2
        class="display-3 font-weight-bold white--text"
        :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
        v-html="title"
      />
      <h2
        class="display-3 primary--text"
        :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
      >
        {{ titleColored }}
      </h2>
      <h2>{{ subtitle }}</h2>
      <v-btn color="primary" to="/contact" rounded class="mt-5" large>
        Contact
      </v-btn>
    </div>
    <v-overlay :class="page()" absolute color="black" opacity=".2">
      <div v-if="$vuetify.breakpoint.smAndDown" class="text-center">
        <h2 class="display-3 font-weight-bold" v-html="title" />
        <h2 class="display-3 primary--text font-weight-bold">
          {{ titleColored }}
        </h2>
        <h2 class="px-10">{{ subtitle }}</h2>
        <v-btn
          v-if="button"
          color="primary"
          to="/contact"
          rounded
          class="mt-5"
          large
        >
          Contact
        </v-btn>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    titleColored: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    button: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getImage() {
      return this.image;
    },
  },
  methods: {
    page() {
      let val = null
      if (this.$route.name == 'Home')
        val = 'fix-overlay'
      return val
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay_text-container {
  position: absolute;
  left: 200px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100;
  max-width: 70vw;
}

// ::v-deep .fix-overlay .v-overlay__scrim {
//   top: px;
// }
</style>
